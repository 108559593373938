import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { dropDownOptions } from 'src/app/modal/DAO';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { Endorsement, PolicyDetail, PortalStatus } from 'src/app/modal/Endorsements/Endorsement.Model';
import { WorkInProgress } from 'src/app/modal/Endorsements/Work-In-Progress.Model';
import { CommonService } from 'src/app/services/commonService';
import { DateService } from 'src/app/services/date.service';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { WIPSearchModal } from 'src/app/component/agentregistration/new-submission/new-submission.component';
import { IEndorsementWIP } from 'src/app/modal/IEndorsementWIP';
import { workInProgress } from 'src/app/modal/routing.models';
import { EndorsementService } from 'src/app/services/endorsement.service';

@Component({
  selector: 'create-endorsement',
  templateUrl: './create-endorsement.component.html',
  styleUrls: ['./create-endorsement.component.css']
})
export class CreateEndorsementComponent  implements OnInit {
  @ViewChild('policySearchForm') policySearchForm: NgForm;
  @ViewChild('endorsementdetailsForm') endorsementdetailsForm: NgForm;
  public maxEndorsementEffectiveDate: Date = new Date();
  public endorsementTypes: Array<dropDownOptions> = Constants.EndorsementTypes;
  public endorsement: Endorsement = new Endorsement();
  public validationMessge: string;

  constructor(
    public router: Router,
    public commonService: CommonService,
    protected authenticationService: AuthenticationService,
    private endorsementService: EndorsementService
  ) {
     
  }

  ngOnInit(): void { }

  public async onSubmit(): Promise<void> {
    this.validationMessge = "";
    if (this.policySearchForm.valid) {
      let endorsementWIP: workInProgress | undefined = undefined;
      await this.getEndorsementWIP().then((results) => {
        endorsementWIP = results;
      });
      if (endorsementWIP) {
        this.routeToEndorsementDetails(endorsementWIP);
      } else {
        await this.getPolicyDetails().then(policyDetail => {
          if (!!policyDetail) {
            this.endorsement = Endorsement.instantiateFromPolicyDetail(policyDetail)
            this.validatePolicyDetails(policyDetail);
          }
          else {
            this.validationMessge = 'Please enter a valid policy number';
          }
        }).catch(error => {
          console.error(error);
        })
      }
    }
  }

  private getPolicyDetails(): Promise<PolicyDetail> {
    let payload = { "urlData": [this.endorsement.policyNumber] };
    return this.commonService.get('endorsement', 'submissionByPolicyNumber', payload).toPromise()
      .then(result => { return result })
      .catch(error => Promise.reject(error));
  }

  private validatePolicyDetails(PolicyDetails: PolicyDetail): void {
    switch (PolicyDetails.status) {
      case PortalStatus.Issued:
        this.maxEndorsementEffectiveDate = this.endorsement.policyExpirationDate;
        this.endorsement.isPolicyDetailsAvailable = true;
        break;
      case PortalStatus.Bound:
        let pendingIssuanceMessage: string = `Policy number ${this.endorsement.policyNumber} is pending issuance. Once complete, you can submit an endorsement request`;
        this.validationMessge = pendingIssuanceMessage;
        break;
      default:
        this.validationMessge = 'Please enter a valid policy number';
        break;
    }
  }

  public onReset(): void {
    this.policySearchForm.resetForm();
    this.endorsementdetailsForm.resetForm();
    this.endorsement = new Endorsement();
  }

  public onContinue(form: NgForm): void {
    if (form.valid) {
      this.postEndorsementDetails().then(endorsementId => {
        if (endorsementId > 0) {
          this.endorsement.endorsementId = endorsementId;
          this.endorsementService.reloadQuestionGroups(this.endorsement).then(
            ()=>{
              let route = Constants.EndorsementBaseRoute + this.endorsement.selectedEndorsementGroupKeys[0];
              this.router.navigateByUrl(route, { state: { endorsement: this.endorsement } });
            }            
          );      
        }
      }).catch(error => {
        console.error(error);
      })
    }
  }

  private postEndorsementDetails(): Promise<number> {
    let endorsementPayload = {
      policyNumber: this.endorsement.policyNumber,
      policyEffectiveDate: this.endorsement.policyEffectiveDate,
      endorsementEffectiveDate: this.endorsement.endorsementEffectiveDate,
      questionGroupKeys: this.endorsement.selectedEndorsementGroupKeys,
      insuredName: this.endorsement.insuredName,
      agencyCode: this.commonService.dao.userAgencyCode,
      agentName: this.commonService.dao.userFullName,
      emailId: this.commonService.dao.userMail,
      proceedAs: this.endorsement.proceedAs.toString(),
      id: 0
    };
    let payload = { "payloadData": endorsementPayload };
    return this.commonService.post('endorsement', 'upsertEndorsementDetail', payload).toPromise()
      .then(result => { return result })
      .catch(error => Promise.reject(error));
  }

  private async getEndorsementWIP(): Promise<workInProgress | undefined> {
    const endorsementWIPSearchModal = new WIPSearchModal();
    endorsementWIPSearchModal.policyNumber = this.endorsement.policyNumber.toString();
    endorsementWIPSearchModal.status = PortalStatus.Incomplete;
    let payload = { "payloadData": endorsementWIPSearchModal };

    return await this.commonService.post('endorsement', 'postEndorsementWIPFilter', payload).toPromise()
      .then(async (data: IEndorsementWIP[]) => {
        if (!!data && data.length > 0) {
          return new WorkInProgress(data[0]);
        }
        else {
          return undefined;
        }
      });
  }

  private async routeToEndorsementDetails(endorsementWIP: workInProgress): Promise<void> {
    const endorsement = Endorsement.instantiateFromWorkInProgress(endorsementWIP);
    endorsement.selectedEndorsementGroups = await this.endorsementService.getEndorsementQuestionGroups(endorsement.endorsementId);
    let route = Constants.EndorsementBaseRoute;
    if (!!endorsement.userIsAt) {
      route += endorsement.userIsAt;
    }
    this.router.navigateByUrl(route, { state: { endorsement: endorsement } });
  }
}